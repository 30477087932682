import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import { PlayerPromotionDrawingDetailsResponse } from '../../../../api/promotions-fetcher/use-fetch-promotion-entries'
import { GenericPopupModal } from '../../../../components/GenericPopupModal/generic-popup-modal-ui'
import { getBlockClass } from '../../../../utilities/helpers'
import { DrawingDetailsTable } from './DrawingDetailsTableUI'
import './promotion-drawing-details-modal-styles.scss'
import { PromotionType } from '../../../../api/promotions-fetcher/constants'

export type PromotionDrawingDetailsModalProps = {
  promotionDrawingDetails?: PlayerPromotionDrawingDetailsResponse
  isLoading: boolean
  isLoadingMore: boolean
  hideLoadingMore: boolean
  error?: string
  onLoadMore: () => void
  handleCloseModal: () => void
}

const ROOT_CLASS = 'promotion-drawing-details-modal'
const TITLE_ROW_CLASS = getBlockClass(ROOT_CLASS, 'title-row')
const TITLE_LABEL_CLASS = getBlockClass(ROOT_CLASS, 'title-label')

export const PromotionDrawingDetailsModalUI = (
  props: PromotionDrawingDetailsModalProps
) => {
  const PromotionDrawingDetailsContents = () => {
    const ErrorMessage = () => (
      <div className={getBlockClass(ROOT_CLASS, 'error')}>{props.error}</div>
    )

    if (props.promotionDrawingDetails) {
      return (
        <div className={getBlockClass(ROOT_CLASS, 'details')}>
          <div className={getBlockClass(ROOT_CLASS, 'title')}>
            <div className={TITLE_ROW_CLASS}>
              <h3 className={TITLE_LABEL_CLASS}>
                {props.promotionDrawingDetails.summary.promotionName}
              </h3>
            </div>
            <div className={TITLE_ROW_CLASS}>
              <h3 className={TITLE_LABEL_CLASS}>
                Drawing {props.promotionDrawingDetails.summary.drawingNumber}
                {
                  PromotionType[props.promotionDrawingDetails.summary.promotionType as keyof typeof PromotionType] === PromotionType.SCAN_TO_ENTER ? 
                  ` - ${props.promotionDrawingDetails.summary.entryCount} ${props.promotionDrawingDetails.summary.entryCount > 1 ? 'Entries' : 'Entry'}` : 
                  ''
                }
              </h3>
            </div>
          </div>
          <DrawingDetailsTable
            summary={props.promotionDrawingDetails.summary}
            scanEvents={props.promotionDrawingDetails.scanEvents}
            onLoadMore={props.onLoadMore}
            hideLoadingMore={props.hideLoadingMore}
            isLoadingMore={props.isLoadingMore}
          />
          {props.error && <ErrorMessage />}
        </div>
      )
    } else if (props.error) {
      return <ErrorMessage />
    } else if (props.isLoading || !props.promotionDrawingDetails) {
      return (
        <div
          className={getBlockClass(ROOT_CLASS, 'progress-container')}
          aria-busy={props.isLoading}
          aria-describedby="progress"
        >
          <CircularProgress
            id="progress"
            style={{ color: 'gray' }}
            size="5em"
            thickness={2}
          />
        </div>
      )
    }
    return null
  }

  return (
    <GenericPopupModal
      isOpen
      closeModal={props.handleCloseModal}
      roundedEdges
      hideCloseButton
    >
      <div className={ROOT_CLASS}>
        <PromotionDrawingDetailsContents />
        <button
          className={getBlockClass(ROOT_CLASS, 'close-button')}
          onClick={props.handleCloseModal}
          aria-label="Close"
        >
          Close
        </button>
      </div>
    </GenericPopupModal>
  )
}
