import React from 'react'
import config from '../../config'
import { permissions } from '../../constants.js'
import { useFetch } from '../../hooks/use-fetch'
import { PromotionType, RewardType, TriggerEvent } from './constants'
import { PromotionEntriesPermissions } from './permissions'

export type DefaultResponse = {
  data: string
}

export enum EntryMethod {
  web = 'WEB',
  mobile = 'MOBILE',
  // UNKNOWN only appears if the scan happened prior to the promotions backend update
  unknown = 'UNKNOWN',
}

export type PlayerPromotionDrawing = {
  promotionId: string
  promotionName: string
  drawingId: string
  drawingNumber: number
  entryCount: number
  scheduledDrawTime: string
  promotionType: string
  triggerEventType: TriggerEvent
}

export type PlayerPromotionDrawingSummary = Omit<PlayerPromotionDrawing, 'entryMethod'>

export type PlayerPromotionDrawingEntries = {
  ticketNumber: string
  entryCount: number
  scanDate: string
  scanMethod: EntryMethod
}

export type PrivatePromotionEntryInfo = {
  promotionName: string
  startDate: string
  endDate: string
  quantity: number
  rewardType: RewardType
  drawingName: string
  drawingNumber: number
  drawingDate?: string
  active: boolean
  entryMethod: EntryMethod
}

export enum ScanEventStatus {
  newEntry = 'NEW_ENTRY',
  existingEntrySamePlayer = 'EXISTING_ENTRY_SAME_PLAYER',
  existingEntryDifferentPlayer = 'EXISTING_ENTRY_DIFFERENT_PLAYER',
  noPromotion = 'NO_PROMOTION',
}

export type PlayerPromotionsResponse = PlayerPromotionDrawingSummary[]

export type PlayerPromotionDrawingDetailsResponse = {
  summary: PlayerPromotionDrawing
  pagination: {
    resultId: string
    resultStart: number
    resultEnd: number
    totalResults: number
  }
  scanEvents: PlayerPromotionDrawingEntries[]
}

export type PromotionEntryDetailsResponse = {
  promotions: PrivatePromotionEntryInfo[]
  status: ScanEventStatus
}

const promotionEntriesBaseUrlV1 = `${config.SERVER_BASE_URL}/api/v1/promotion-entries`
const promotionEntriesBaseUrlV2 = `${config.SERVER_BASE_URL}/api/v2/promotion-entries`
const getPlayerPromotionsUrl = (playerId: string) =>
  `${promotionEntriesBaseUrlV1}/player/${playerId}`

const getPromotionEntryDetailsUrl = (playerId: string, barcode: string) =>
  `${promotionEntriesBaseUrlV1}/player/${playerId}/barcode/${barcode}`
const getDisableBarcodeUrl = (barcode?: string) =>
  `${promotionEntriesBaseUrlV1}/disable/${barcode}`

const getEntryDetailsUrl = (
  playerId: string,
  promotionId: string,
  drawingId: string,
  resultStart: number,
  resultEnd: number
) => {
  const searchParams = new URLSearchParams({
    resultStart: String(resultStart),
    resultEnd: String(resultEnd),
  })
  return `${promotionEntriesBaseUrlV2}/player/${playerId}/promotion/${promotionId}/drawing/${drawingId}?${searchParams.toString()}`
}

export const useFetchPromotionEntryDetails = (
  playerId: string,
  barcode: string
) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PromotionEntryDetailsResponse>()

  React.useEffect(() => {
    fetchUrl(
      getPromotionEntryDetailsUrl(playerId, barcode),
      'GET',
      {},
      undefined,
      [
        PromotionEntriesPermissions.CAN_READ_PROMOTION_ENTRIES,
        permissions.CAN_SEE_TICKET_SCANNING_ACTIVITY,
      ]
    )
    return () => {}
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}

export const useFetchPlayerPromotions = (playerId: string) => {
  const { isLoading, error, data, fetchUrl } =
    useFetch<PlayerPromotionsResponse>()

  React.useEffect(() => {
    fetchUrl(getPlayerPromotionsUrl(playerId), 'GET', {}, undefined, [
      PromotionEntriesPermissions.CAN_READ_PROMOTION_ENTRIES,
    ])
    return () => {}
  }, [])

  return {
    isLoading,
    error,
    data,
  }
}

const ENTRIES_RESULTS_PER_FETCH = 30

export const useFetchPlayerPromotionDrawingDetails = (
  playerId: string,
  promotionIdentifier: string,
  drawingId: string
) => {
  const [resultStart, setResultStart] = React.useState<number>(0)
  const { isLoading, error, data, fetchUrl } =
    useFetch<PlayerPromotionDrawingDetailsResponse>()
  const [scanEventData, setScanEventData] = React.useState<
    PlayerPromotionDrawingEntries[]
  >([])

  React.useEffect(() => {
    if (data?.scanEvents.length) {
      setScanEventData([...scanEventData, ...data.scanEvents])
    }
  }, [data])

  React.useEffect(() => {
    const resultEnd = resultStart + ENTRIES_RESULTS_PER_FETCH
    const url = getEntryDetailsUrl(
      playerId,
      promotionIdentifier,
      drawingId,
      resultStart,
      resultEnd
    )
    fetchUrl(url, 'GET', {}, undefined, [
      PromotionEntriesPermissions.CAN_READ_PROMOTION_ENTRIES,
    ])
    return () => {}
  }, [promotionIdentifier, drawingId, resultStart])

  const handleLoadMore = () =>
    setResultStart(resultStart + ENTRIES_RESULTS_PER_FETCH)

  return {
    isLoading,
    error,
    data,
    scanEventData,
    handleLoadMore,
  }
}

export const useFetchDisableBarcode = () => {
  const { isLoading, error, requestSuccessful, fetchUrl } =
    useFetch<DefaultResponse>()

  const disableBarcode = async (barcode: string) =>
    await fetchUrl(getDisableBarcodeUrl(barcode), 'PUT', {}, undefined, [
      PromotionEntriesPermissions.CAN_DELETE_PROMOTION_ENTRIES,
    ])

  return {
    disableBarcode,
    isLoading,
    error,
    requestSuccessful,
  }
}
