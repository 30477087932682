import * as React from 'react'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { Link } from 'react-router-dom'
import './styles.scss'
import { getBlockClass } from '../../../utilities/helpers'
import { userHasAnyOfPermissions } from '../../../util/access-helpers'
import { permissions } from '../../../constants.js'

const ROOT_CLASS = 'year-end-reporting-page'

export const YearEndReportingPage = () => {
  const links = []
  if (userHasAnyOfPermissions([permissions.CAN_SEE_YEAR_END_REPORTS])) {
    links.push({
      path: '/new-layout/reporting/finance/reporting/year-end/w2g',
      label: 'Year End Reporting',
    })
  }
  if (userHasAnyOfPermissions([permissions.CAN_SEE_1099_REPORTS])) {
    links.push({
      path: '/new-layout/reporting/finance/reporting/year-end/agent-1099',
      label: 'Agent 1099 Reports',
    })
  }
  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI message="Back to Reporting" />
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        Year End Reporting
      </header>
      <section className={getBlockClass(ROOT_CLASS, 'content')}>
        {links.map((link) => (
          <Link
            key={link.label}
            className={getBlockClass(ROOT_CLASS, 'link')}
            to={link.path}
          >
            {link.label}
          </Link>
        ))}
      </section>
    </div>
  )
}
