import * as React from 'react'
import './styles.scss'
import { BackButtonUI } from '../../../components/CommonComponents/BackButtonComponent/back-button-ui'
import { getBlockClass, joinClasses } from '../../../utilities/helpers'
import {
  CustomDropdownField,
  CustomDropdownOption,
} from '../../../components/CommonComponents/custom-dropdown-component/custom-dropdown-ui'
import { AsyncContainerUI } from '../../../components/CommonComponents/async-container-component/async-container-ui'
import {
  Agent1099ReportListEntry,
  Agent1099YearsResponse,
} from '../../../api/agent-1099-reports/api-types'
import { SearchResultsTableUI } from '../../../components/CommonComponents/SearchResultsTableComponent/search-results-table-ui'
import { COLUMN_MAP, MappedAgent1099ReportListEntry } from './constants'
import { generateReportDateString } from '../helper'
import { mapFinservReportStatusToString } from '../../../util'
import { ReportStatus } from '../../../api/finserv-general-reports/api-types'

export type Agent1099YearEndReportingPageProps = {
  isLoading: boolean
  yearsResponse?: Agent1099YearsResponse
  generateReportForYear: (year: string) => void
  fetchReportBundle?: (id: string) => void
  reports?: Agent1099ReportListEntry[]
  error: any
}
const ROOT_CLASS = 'agent-1099-year-end-reporting-page'

const DEFAULT_OPTION = {
  display: 'Select...',
  key: 'select',
  value: 'select',
  disabled: true,
}
export const Agent1099YearEndReportingPageUI = (
  props: Agent1099YearEndReportingPageProps
) => {
  const [dropdownOptions, setDropdownOptions] = React.useState<
    CustomDropdownOption[]
  >([])
  const [selectedYear, setSelectedYear] =
    React.useState<CustomDropdownOption>(DEFAULT_OPTION)
  const [mappedRows, setMappedRows] = React.useState<
    MappedAgent1099ReportListEntry[]
  >([])

  React.useEffect(() => {
    if (props.yearsResponse) {
      const mappedOptions = props.yearsResponse.years.map(
        (year): CustomDropdownOption => {
          return {
            key: year.year,
            value: year.year,
            display: year.year,
          }
        }
      )
      setDropdownOptions([DEFAULT_OPTION, ...mappedOptions])
    }
  }, [props.yearsResponse])

  const handleDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const option = dropdownOptions.find(
      (option) => option.value === event.target.value
    )
    if (!option) {
      throw new Error('Could not find selected year value')
    }
    setSelectedYear(option)
  }

  React.useEffect(() => {
    if (props.reports && selectedYear) {
      const rows = props.reports.map(
        (report): MappedAgent1099ReportListEntry => ({
          ...report,
          dateGenerated: generateReportDateString(report.dateGenerated),
          status: mapFinservReportStatusToString(report.status),
        })
      )
      setMappedRows(
        rows.filter((row) => row.paymentYear === selectedYear.value)
      )
    }
  }, [selectedYear, props.reports])

  const generateDownloadCTA = (row: MappedAgent1099ReportListEntry) => {
    const statusesToDisplay = [ReportStatus.SUCCESS, ReportStatus.PENDING].map(
      mapFinservReportStatusToString
    )
    if (statusesToDisplay.includes(row.status)) {
      return (
        <button
          className={getBlockClass(ROOT_CLASS, 'download-button')}
          disabled={
            row.status !== mapFinservReportStatusToString(ReportStatus.SUCCESS)
          }
          onClick={() => props.fetchReportBundle?.(row.id)}
        >
          Download All
        </button>
      )
    }
  }

  return (
    <div className={ROOT_CLASS}>
      <BackButtonUI message="Back to Year End Reporting" />
      <header className={getBlockClass(ROOT_CLASS, 'header')}>
        Year End 1099 Agent Report
      </header>
      <AsyncContainerUI
        isLoading={props.isLoading}
        error={props.error}
        color="white"
      >
        <section
          className={joinClasses([
            getBlockClass(ROOT_CLASS, 'content'),
            getBlockClass(ROOT_CLASS, 'generate-section'),
          ])}
        >
          <header className={getBlockClass(ROOT_CLASS, 'generation-header')}>
            Generate Reports
          </header>
          <section className={getBlockClass(ROOT_CLASS, 'generation-controls')}>
            <section
              className={getBlockClass(ROOT_CLASS, 'generation-dropdown')}
            >
              <label className={getBlockClass(ROOT_CLASS, 'generation-label')}>
                Select Year
              </label>
              <CustomDropdownField
                options={dropdownOptions}
                name="year"
                onChange={(event) => handleDropdownChange(event)}
                value={selectedYear?.value as string}
                id="agent-1099-year-end-year-dropdown"
                disabled={false}
              ></CustomDropdownField>
            </section>
            <button
              className={getBlockClass(ROOT_CLASS, 'button')}
              onClick={() =>
                props.generateReportForYear(selectedYear.value as string)
              }
            >
              Generate
            </button>
          </section>
        </section>
        <section
          className={joinClasses([
            getBlockClass(ROOT_CLASS, 'content'),
            getBlockClass(ROOT_CLASS, 'results-table'),
          ])}
        >
          <SearchResultsTableUI
            rowData={mappedRows ?? []}
            columnMap={COLUMN_MAP}
            additionalResults={false}
            generateRowCTA={generateDownloadCTA}
            noDataMessage={
              selectedYear === DEFAULT_OPTION
                ? 'Select a year to generate reports'
                : 'No reports for selected year'
            }
          />
        </section>
      </AsyncContainerUI>
    </div>
  )
}
