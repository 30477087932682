import * as React from 'react'
import { Agent1099YearEndReportingPageUI } from './agent-1099-year-end-reporting-page-ui'
import {
  useFetchAgent1099AvailableYears,
  useFetchAgent1099ReportMetadata,
  useFetchDownloadAgent1099Report,
  useFetchGenerateYearEnd1099Report,
} from '../../../api/agent-1099-reports'
import { Agent1099ReportType } from '../../../api/agent-1099-reports/api-types'

export const Agent1099YearEndReportingPageContainer = () => {
  const availableYearsFetcher = useFetchAgent1099AvailableYears()
  const reportMetadataFetcher = useFetchAgent1099ReportMetadata()
  const reportBundleFetcher = useFetchDownloadAgent1099Report()
  const generateReportFetcher = useFetchGenerateYearEnd1099Report()
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true)
  React.useEffect(() => {
    availableYearsFetcher.fetchAvailableYears()
    reportMetadataFetcher
      .fetch1099ReportsMetadata()
      .then(() => setFirstLoad(false))
  }, [])

  const isLoading =
    availableYearsFetcher.isLoading ||
    (reportMetadataFetcher.isLoading && firstLoad) ||
    generateReportFetcher.isLoading
  const error =
    availableYearsFetcher.error ??
    reportMetadataFetcher.error ??
    generateReportFetcher.error

  const generateForYear = (year: string) => {
    generateReportFetcher
      .generateReportBundleForYear(year)
      .then(() => setTimeout(() => reportMetadataFetcher.fetch1099ReportsMetadata(), 1000))
      .then(() => {
        reportMetadataFetcher.autoReload()
      })
  }

  return (
    <Agent1099YearEndReportingPageUI
      isLoading={isLoading}
      yearsResponse={availableYearsFetcher.data}
      reports={reportMetadataFetcher.data?.reports.filter(
        (report) => report.reportType === Agent1099ReportType.YEAR_END_REPORTS
      )}
      error={error}
      fetchReportBundle={reportBundleFetcher.preSignedUrlHandler}
      generateReportForYear={generateForYear}
    />
  )
}
